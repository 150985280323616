<template>
  <div class="goodsList">
    <hot-goods :hotProducts="hotProducts"></hot-goods>
    <all-goods :allProducts="allProducts"></all-goods>
  </div>
</template>
<script>
import HotGoods from './HotGoods'
import AllGoods from './AllGoods'
export default {
  name: 'GoodsList',
  props: ['hotProducts', 'allProducts'],
  components: {
    HotGoods,
    AllGoods
  },
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.goodsList {
  width: 92%;
  margin: 0 auto;
  padding-bottom: 15px;
}
</style>
