<template>
  <div class="storeTop">
    <div class="box">
      <div class="info">
        <figure class="userImg">
          <van-image
            class="userImgIcon"
            :src="
              shopData.ShopHeadPhoto && imgUrlFilter(shopData.ShopHeadPhoto)
            "
            @click="imagePreview(shopData.ShopHeadPhoto)"
          >
            <template v-slot:error>
              <img
                class="ImgIcon"
                src="~assets/img/userManage/user_logo@2x.png"
              />
            </template>
          </van-image>
        </figure>
        <section class="infoRight">
          <p class="infoRightUserName">{{ shopData.ShopName }}</p>
          <p class="infoRightUserP">{{ shopData.ShopDescription }}</p>
        </section>
      </div>
    </div>
    <div class="bottom"></div>
    <!-- 搜索 -->
    <div class="searchBox">
      <div class="searchInfo">
        <input
          class="searchInput"
          v-model="search"
          :placeholder="$t.cStore.pleaseEnterContent"
        />
        <figure class="searchLogo" @click="serachShop">
          <img
            class="searchLogoIcon"
            src="~assets/img/client/icon_home_search@2x.png"
          />
        </figure>
      </div>
    </div>
  </div>
</template>
<script>
import { Image } from "vant";
import { Row, Col } from "vant";
import Vue from "vue";

Vue.use(Image);
Vue.use(Row).use(Col);
export default {
  name: "StoreTop",
  props: ["shopData"],
  components: {},
  data() {
    return {
      search: "",
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    imagePreview(image) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: [image],
      });
    },
    serachShop() {
      this.$emit("serachShop", this.search);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.storeTop {
  width: 100%;
  position: relative;
  .bottom {
    background-color: #f6f6f6;
    height: 27.5px;
    width: 100%;
  }
  .box {
    width: 100%;
    background-image: url("~assets/img/client/bg_1@2x.png");
    background-size: cover;
    height: 220px;
    .info {
      width: 90%;
      margin: 0 auto;
      padding: 115px 0 5px 0;
      @include publicFlex;
      .userImg {
        margin: 0;
        padding: 0;
        width: 23%;
        text-align: center;
        .userImgIcon {
          width: 56px;
          height: 56px;
          border-radius: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          .ImgIcon {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__img {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__error {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
            border-radius: 100%;
          }
        }
      }
      .infoRight {
        width: 77%;
        .infoRightUserName {
          margin: 0;
          padding: 0;
          font-size: 17px;
          color: #ffffff;
          width: 100%;
          font-weight: bold;
        }
        .infoRightUserP {
          margin: 0;
          padding: 0;
          margin-top: 8px;
          font-size: 14px;
          color: #ffffff;
          width: 100%;
        }
      }
    }
  }
  .searchBox {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    .searchInfo {
      margin: 0 auto;
      width: 80%;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 30px;
      background-color: #ffffff;
      position: relative;
      .searchInput {
        outline: none;
        border: none;
        background-color: transparent;
        padding: 14px 20px;
        font-size: 14px;
        color: #a7a7a7;
        width: 100%;
      }
      .searchLogo {
        @include publicFlex;
        margin: 0;
        padding: 0;
        position: absolute;
        justify-content: center;
        -webkit-justify-content: center;
        right: 15px;
        top: 10px;
        .searchLogoIcon {
          width: 20px;
          height: 20px;
          object-fit: cover;
          -webkit-object-fit: cover;
        }
      }
    }
  }
}
</style>
